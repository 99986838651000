// 保険始期用
export const startDateData = [{ key: 'startDate', value: '', titleLineFlg: false }];

// 申込人氏名・住所用
export const baseData = [
  { key: 'fullName', value: '', titleLineFlg: false },
  { key: 'fullNameKana', value: '', titleLineFlg: false },
  { key: 'fullAddress', value: '', titleLineFlg: false },
  { key: 'personType', value: '', titleLineFlg: false },
  { key: 'birthday', value: '', titleLineFlg: false },
  { key: 'sex', value: '', titleLineFlg: false },
  { key: 'firstPhoneNumber', value: '', titleLineFlg: false },
  { key: 'secondPhoneNumber', value: '', titleLineFlg: false },
  { key: 'phoneNumber', value: '', titleLineFlg: false },
  { key: 'extension', value: '', titleLineFlg: false },
  { key: 'name', value: '', titleLineFlg: false },
  { key: 'code', value: '', titleLineFlg: false },
  { key: 'employeeNumber', value: '', titleLineFlg: false },
];

// 保険の対象の被保険者（所有者）氏名・家族構成用
export const insuredData = [
  { key: 'insuredFullName', value: '', titleLineFlg: false },
  { key: 'insuredFullNameKana', value: '', titleLineFlg: false },
  { key: 'firstPhoneNumber', value: '', titleLineFlg: false },
  { key: 'secondPhoneNumber', value: '', titleLineFlg: false },
  { key: 'insuredAge', value: '', titleLineFlg: false },
  { key: 'familyStructure', value: '', titleLineFlg: false },
];
